export const supportedLanguages = [
    "English",
    "Spanish",
    "French",
    "German",
    "Portuguese",
    "Italian",
    "Dutch",
    "Russian",
    "Chinese (Simplified)",
    "Chinese (Traditional)",
    "Japanese",
    "Korean",
    "Arabic",
    "Hindi",
    "Bengali",
    "Urdu",
    "Persian/Farsi",
    "Turkish",
    "Polish",
    "Swedish",
    "Danish",
    "Norwegian",
    "Finnish",
    "Greek",
    "Thai",
    "Hebrew",
    "Indonesian",
    "Vietnamese",
    "Malay",
    "Romanian",
    "Czech",
    "Hungarian",
    "Slovak",
    "Ukrainian",
    "Bulgarian",
    "Serbian",
    "Croatian",
  ];