import React, { useCallback, useState } from "react";
import { Input, Modal, Select, Form, Tabs } from "antd";
import handleStepsData from "../../utils/home/handleStepsData";
import saveAsNewProblem from "../../utils/home/saveAsNewProblem";
import { supportedLanguages } from "../../Constants/index";
import { useDropzone } from "react-dropzone";
import { FaFileUpload } from "react-icons/fa";
const { TextArea } = Input;
const { Option, OptGroup } = Select;

const AddProblemModal = ({
  addProblemModal,
  addProblemLoading,
  setProblem,
  setAddProblemModal,
  setEditProblemModal,
  clientErrors,
  promptlets,
  problem,
  setClientErrors,
  dispatch,
}) => {
  const [error, setError] = useState("");
  const handleCancel = () => {
    setProblem({
      title: "",
      description: "",
      library: "",
      language: "",
    });
    setAddProblemModal(false);
    setClientErrors({});
  };

  const handleSave = () => {
    saveAsNewProblem(
      problem,
      setClientErrors,
      dispatch,
      setProblem,
      setAddProblemModal,
      setEditProblemModal
    );
  };
  const stepsData = handleStepsData(null, promptlets);
  const handlePromptletSelection = (value) => {
    const selectedPromptlet = promptlets?.filter(
      (promptlets) => promptlets?._id == value
    );
    setProblem({
      ...problem,
      library: value,
      description: selectedPromptlet[0]?.demoProblem,
      title: selectedPromptlet[0]?.name,
    });
  };
  const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
  const onDrop = useCallback((acceptedFiles) => {
    // Filter out only PDF and text files
    const filteredFiles = acceptedFiles.filter(
      (file) =>
        (file.type === "application/pdf" || file.type === "text/plain") &&
        maxSize
    );

    if (filteredFiles.length > 0) {
      setError("");
      // Do something with the filtered files
      console.log("Accepted files:", filteredFiles);
    } else {
      setError("No valid files accepted. Please upload PDF or text files.");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [], // Accept PDF files
      "text/plain": [],
    },
    maxSize: maxSize,
  });
  const tabs = ["Problem Description", "Attachments"];
  const tabChildren = [
    <div>
      <Form.Item
        validateStatus={clientErrors?.description ? "error" : ""}
        help={clientErrors?.description}
        className="text-md font-bold"
      >
        <TextArea
          placeholder="Problem Description"
          size="large"
          value={problem?.description}
          onChange={(e) =>
            setProblem({ ...problem, description: e.target.value })
          }
          style={{ height: "150px" }}
        />
      </Form.Item>
    </div>,
    <div
      {...getRootProps()}
      className="flex flex-col h-[174px] items-center justify-center border-2 border-dashed border-blue-600 rounded-lg p-4 hover:bg-blue-50 transition duration-200 ease-in-out"
    >
      <FaFileUpload
        fontSize={40}
        className="text-blue-600 mb-2 cursor-pointer"
      />
      <input {...getInputProps()} className="hidden" />
      <span className="text-blue-600 text-sm">
        {error ? (
          <span className="text-red-500">{error}</span>
        ) : (
          " Drag & drop your files here or click to upload "
        )}
      </span>
    </div>,
  ];
  return (
    <Modal
      centered
      open={addProblemModal}
      onCancel={handleCancel}
      onOk={handleSave}
      okText="Save"
      confirmLoading={addProblemLoading}
      width={900}
      bodyStyle={{ minHeight: 400 }}
      title="Add Problem"
    >
      <div className="flex flex-col">
        <Form layout="vertical">
          <div className="flex flex-col md:flex-row gap-4">
            {/* Select Promptlet */}
            <div className="md:w-[70%] w-full">
              <Form.Item
                label="Library"
                validateStatus={clientErrors?.library ? "error" : ""}
                help={clientErrors?.library}
                className="text-md font-bold"
              >
                <Select
                  size="large"
                  placeholder="Choose Library"
                  value={problem?.library || undefined}
                  onChange={(value) => handlePromptletSelection(value)}
                >
                  <OptGroup label="My Promptlets">
                    {stepsData?.myPromptlets?.map((item) => (
                      <Option key={item.value} value={item.value}>
                        <div className="flex justify-between px-2">
                          <span className="truncate">{item.label}</span>
                          <span className="text-[#8c8c8c] italic pl-2">
                            {item.author}
                          </span>
                        </div>
                      </Option>
                    ))}
                  </OptGroup>
                  <OptGroup label="Shared Promptlets">
                    {stepsData?.communityPromptlets?.map((item) => (
                      <Option key={item.value} value={item.value}>
                        <div className="flex justify-between px-2">
                          <span className="truncate">{item.label}</span>
                          <span className="text-[#8c8c8c] italic pl-2">
                            {item.author}
                          </span>
                        </div>
                      </Option>
                    ))}
                  </OptGroup>
                </Select>
              </Form.Item>
            </div>
            {/* Select Language */}
            <div className="md:w-[28%] w-full">
              <Form.Item label="Language" className="text-md font-bold">
                <Select
                  size="large"
                  placeholder="Select Language"
                  value={problem?.language || undefined}
                  onChange={(value) =>
                    setProblem({ ...problem, language: value })
                  }
                >
                  {supportedLanguages?.map((language) => (
                    <Option key={language} value={language}>
                      {language}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <Form.Item
            label="Problem Title"
            validateStatus={clientErrors?.title ? "error" : ""}
            help={clientErrors?.title}
            className="text-md font-bold"
          >
            <Input
              placeholder="Problem Title"
              size="large"
              value={problem?.title}
              onChange={(e) =>
                setProblem({ ...problem, title: e.target.value })
              }
            />
          </Form.Item>
          <Tabs
            type="card"
            items={tabs.map((tabName, index) => {
              const id = String(index + 1);
              return {
                label: tabName,
                key: id,
                children: tabChildren[index],
              };
            })}
          />
        </Form>
      </div>
    </Modal>
  );
};

export default AddProblemModal;
